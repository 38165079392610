export const getNexioParams = () => {
  const params = new URLSearchParams(decodeURI(location.search))
  const nexioId = params.get('id')
  const nexioStatus = params.get('status')
  const nexioTransactionUid = params.get('tuid')
  const nexioOrderNumber = params.get('orderNumber')
  const nexioMOrderNumber = params.get('mOrderNumber')
  const nexioError = params.get('error')
  const nexioErrorMessage = params.get('message')
  const gatewayStatus = params.get('gatewayResponse[status]')
  return {
    nexioId,
    nexioStatus,
    nexioTransactionUid,
    nexioOrderNumber,
    nexioMOrderNumber,
    nexioError,
    nexioErrorMessage,
    gatewayStatus,
  }
}

export const assertNexioZeroDollarParams = (): boolean => {
  const {
    nexioId,
    nexioStatus,
    nexioTransactionUid,
    nexioError,
    nexioErrorMessage,
  } = getNexioParams()
  return (
    nexioId !== null ||
    nexioStatus !== null ||
    nexioTransactionUid !== null ||
    nexioError !== null ||
    nexioErrorMessage !== null
  )
}

export const assertNexioParams = () => {
  const { nexioOrderNumber, nexioMOrderNumber } = getNexioParams()
  return (
    assertNexioZeroDollarParams() ||
    nexioOrderNumber !== null ||
    nexioMOrderNumber !== null
  )
}
