import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import {
  Seo,
  Basic as AltButton,
  Loading,
  PageHeader,
  Nexio3dsModal,
} from '../components'
import CreditCard from '../components/CreditCard'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Styles
import { PaymentInfoContainer, StyledForm } from '../styles/LoginPage.styles'
import { completeNexioTransaction } from '../utils/cartHelpers'
import { auth } from '../services/firebase'
import { QService } from '../services/q-services'
import * as Sentry from '@sentry/react'
import {
  assertNexioParams,
  assertNexioZeroDollarParams,
  getNexioParams,
} from '../utils/3dsHelpers'

const PaymentInfo = ({ location }) => {
  const {
    isAuthenticated,
    getSelectedCard,
    cardsOnFile,
    updateSelectedCard,
    setShowNexio3dsModal,
    showNexio3dsModal,
    nexio3dsUrl,
    setNexio3dsUrl,
    isLoading3ds,
    setIsLoading3ds,
    pendingUid3ds,
    setPendingUid3ds,
    isLoadingCards,
  } = useAuthContext()
  const {
    virtualAutoShipId,
    virtualAutoShipData,
    autoShipId,
    autoShipData,
    handleGetAutoShipById,
    manageAutoShip,
    handleSetAutoShipState,
    handleSetVirtualAutoShipState,
  } = useCartContext()
  const { isStoreLoading } = useStoreContext()
  const [selectedCardId, setSelectedCardId] = useState(
    getSelectedCard().creditCardGuid
  )
  const {
    storeCountryCode,
    prismicData: {
      prismicGeneral,
      prismicPaymentInfoPage: {
        add_new_credit_debit_card,
        loading_cards,
        payment_information,
        update_payment_info,
        payment_method_not_available,
      },
      prismicNexioRedirect: { url_loading_3ds_text },
    },
  } = usePrismic()

  const returnUrl = location?.state?.returnUrl || '/cart'
  const inVirtual = location?.state?.inVirtual || false
  // useEffect(() => {
  //   if (!isAuthenticated) navigate('/')
  // }, [])

  // nexio3ds
  useEffect(() => {
    if (!nexio3dsUrl) return
    setIsLoading3ds(false)
    setShowNexio3dsModal(true)
  }, [nexio3dsUrl])

  // auto select the card that is being used for subscriptions - override what is saved as default card in local storage
  useEffect(() => {
    if (returnUrl !== '/subscriptions') return
    const paymentInformation =
      inVirtual == false
        ? autoShipData?.associatePaymentInformationId
        : virtualAutoShipData?.associatePaymentInformationId
    const autoShipCard = cardsOnFile?.find(
      card => card.associatePaymentInformationId === paymentInformation
    )
    setSelectedCardId(autoShipCard?.creditCardGuid)
  }, [returnUrl])

  const onSubmit = async event => {
    event.preventDefault()
    const selectedCard = cardsOnFile.find(({ creditCardGuid }) => {
      return creditCardGuid === selectedCardId
    })

    if (returnUrl === '/cart') {
      // TODO: need to add in how we store the selected card
      updateSelectedCard(selectedCard)
    } else {
      if (!autoShipId && !inVirtual) {
        handleSetAutoShipState({
          autoShipPaymentInformationId:
            selectedCard.associatePaymentInformationId,
        })
      } else if (!virtualAutoShipId && inVirtual) {
        handleSetVirtualAutoShipState({
          virtualAutoShipPaymentInformationId:
            selectedCard.associatePaymentInformationId,
        })
      } else {
        let data = {}
        if (!inVirtual) {
          data = {
            autoShipId,
            dateNextProcess: autoShipData.dateNextProcess,
            isActive: true,
            associatePaymentInformationId:
              selectedCard.associatePaymentInformationId,
          }
        } else {
          data = {
            autoShipId: virtualAutoShipId,
            dateNextProcess: virtualAutoShipData.dateNextProcess,
            isActive: true,
            associatePaymentInformationId:
              selectedCard.associatePaymentInformationId,
          }
        }
        await manageAutoShip
          .update(data, handleGetAutoShipById, prismicGeneral)
          .then(() =>
            toast.success(prismicGeneral.msg_subscription_payment_info_updated)
          )
      }
    }
    await navigate(returnUrl)
  }
  const reAddAuthHeader = () => {
    const user = auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    QService.setToken(token)
  }

  const confirmTransaction = () => {
    const {
      nexioStatus,
      nexioTransactionUid,
      nexioMOrderNumber,
      nexioError,
      nexioErrorMessage,
      gatewayStatus,
    } = getNexioParams()
    if (nexioError && nexioErrorMessage) {
      completeNexioTransaction(nexioTransactionUid, true)
        .then(() => {
          setIsLoading3ds(false)
        })
        .catch(() => {
          setIsLoading3ds(false)
          console.log(nexioErrorMessage, 'nexioErrorMessage')
        })
    } else {
      completeNexioTransaction(nexioTransactionUid, false)
        .then(() => {
          setIsLoading3ds(false)
          toast.success(prismicGeneral.msg_new_card_added)
        })
        .catch(error => {
          setIsLoading3ds(false)
          console.log(error)
        })
    }
    window.sessionStorage.removeItem('nexio3ds')
  }

  useEffect(() => {
    if (
      auth.currentUser &&
      window.sessionStorage.getItem('nexio3ds') &&
      assertNexioZeroDollarParams()
    ) {
      reAddAuthHeader()
      confirmTransaction()
    }
  }, [auth.currentUser])

  const handleNexio3dsModalYes = () => {
    setShowNexio3dsModal(false)
    window.sessionStorage.setItem('nexio3ds', 'true')
    setIsLoading3ds(true)
    window.location.href = nexio3dsUrl
    setNexio3dsUrl('')
  }

  const handleNexio3dsModalClose = () => {
    setShowNexio3dsModal(false)
    setNexio3dsUrl('')
    completeNexioTransaction(pendingUid3ds, true)
      .then(() => {
        setPendingUid3ds(null)
      })
      .catch(e => {
        Sentry.captureException(e)
      })
  }

  if (isLoading3ds)
    return (
      <Loading loading={isLoading3ds} message={url_loading_3ds_text[0].text} />
    )
  if (isLoadingCards) {
    return <Loading loading={isLoadingCards} message={loading_cards[0].text} />
  }
  if (isStoreLoading)
    return <Loading loading={isStoreLoading} message={loading_cards[0].text} />
  return (
    <>
      <Seo title={update_payment_info[0].text} />
      <PageHeader exitRoute={returnUrl}>
        {payment_information[0].text}
      </PageHeader>
      <PaymentInfoContainer>
        <StyledForm onSubmit={onSubmit}>
          {cardsOnFile?.length > 0 &&
            cardsOnFile.map(creditCard => (
              <CreditCard
                creditCard={creditCard}
                selectedCardId={selectedCardId}
                setSelectedCardId={setSelectedCardId}
                key={creditCard.creditCardGuid}
                associatePaymentInformationId={
                  creditCard?.associatePaymentInformationId
                }
                disabled={
                  creditCard?.creditCardType?.toLowerCase() ===
                    'americanexpress' && storeCountryCode === 'ca'
                }
                disabledMessage={
                  payment_method_not_available ?? 'Payment method not supported'
                }
              />
            ))}
        </StyledForm>
        <AltButton
          onClick={() =>
            navigate('/add-card', {
              state: {
                returnUrl,
              },
            })
          }
        >
          {add_new_credit_debit_card[0].text}
        </AltButton>
      </PaymentInfoContainer>
      <Nexio3dsModal
        open={showNexio3dsModal}
        onClose={handleNexio3dsModalClose}
        onYes={handleNexio3dsModalYes}
        onCard={true}
      />
    </>
  )
}

export default PaymentInfo
