import React from 'react'
// Components
import { Primary as PrimaryButton, Warning as DeleteButton } from '..'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
// Styles
import { Container } from '../../styles/LoginPage.styles'
import { CardTitle, DataError, UnderlinedContainer } from './CreditCard.styles'
// Types
import { CreditCard } from '../../types/PaymentsTypes'
// Utils
import { getMMyyyyFormat } from '../../utils/dateHelpers'

type CreditCardProps = {
  creditCard: CreditCard
  selectedCardId: string
  setSelectedCardId: (s: string) => void
  associatePaymentInformationId: number
  disabled: boolean
  disabledMessage: string
}

const CreditCardComponent = ({
  creditCard,
  selectedCardId,
  setSelectedCardId,
  associatePaymentInformationId,
  disabled = false,
  disabledMessage,
}: CreditCardProps) => {
  const {
    prismicData: {
      prismicPaymentInfoPage: { card_missing_error, expires, use_this_payment },
    },
  } = usePrismic()
  const { deleteCreditCard } = useAuthContext()

  const { lastFourDigits, creditCardGuid, creditCardType, expirationDate } =
    creditCard

  if (
    !lastFourDigits ||
    !creditCardGuid ||
    !creditCardType ||
    !expirationDate
  ) {
    return (
      <UnderlinedContainer>
        <DataError>{card_missing_error[0].text}</DataError>
      </UnderlinedContainer>
    )
  }

  const handleChanged: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const selectedCardId = target.value
    setSelectedCardId(selectedCardId)
  }

  const isSelected = selectedCardId === creditCardGuid

  return (
    <UnderlinedContainer disabled={disabled}>
      {disabled && <span>{disabledMessage}</span>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Container key={lastFourDigits}>
          <input
            checked={isSelected}
            data-qa={`radioButton-${lastFourDigits}`}
            id={creditCardGuid}
            name="creditCard"
            onChange={handleChanged}
            type="radio"
            value={creditCardGuid}
            disabled={disabled}
          />
          <Container direction="column" align="start" padding="0 40px">
            <CardTitle
              data-qa={`cardTitle-${lastFourDigits}`}
            >{`${creditCardType} **** ${lastFourDigits}`}</CardTitle>
            <span>{`${expires[0].text} ${getMMyyyyFormat(
              expirationDate
            )}`}</span>
          </Container>
        </Container>
        <DeleteButton
          style={{ width: '40px' }}
          icon="trash alternate outline"
          onClick={e => {
            e.preventDefault()
            deleteCreditCard(creditCardGuid, associatePaymentInformationId)
          }}
        />
      </div>
      <Container justify="center" padding="0 .5em">
        <PrimaryButton
          style={{
            width: '60%',
            margin: '0',
            display: isSelected ? 'flex' : 'none',
          }}
          type="submit"
          data-qa={`usePaymentButton-${lastFourDigits}`}
          disabled={disabled}
        >
          {use_this_payment[0].text}
        </PrimaryButton>
      </Container>
    </UnderlinedContainer>
  )
}

export default CreditCardComponent
